<script>
import { mapGetters } from 'vuex';
import { importTasksV2 } from '@/services/api/v2/tasks.v2.api';
import moment from 'moment';

export default {
  name: 'DhmeHallDashboardAssemblySubTaskCard',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      reload: false,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleId: 'dhmeAssemblyHallDashboardStore/moduleId',
    }),

    taskTitle() {
      if (this.task.task_type.metadata && this.language) {
        return (
          JSON.parse(this.task.task_type.metadata)[this.language] ??
          this.task.title
        );
      }
      return this.task.title;
    },
  },
  watch: {
    task: {
      handler(value) {
        this.reload = true;
        this.$nextTick(() => (this.reload = false));
      },
      deep: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    formatTime(date) {
      return moment(date).format('H:mm');
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.stationDashboard.${path}`, params);
    },
    async reopenTask() {
      await importTasksV2([
        {
          id: this.task.id,
          status: 'open',
        },
      ]);
      this.task.status = 'open';
      this.$store.commit('showNotification', {
        content: `Task status updated to open`,
        color: 'info',
      });
    },
  },
};
</script>

<template>
  <v-card
    v-if="!reload"
    class="mb-2 sub-task-card-container"
    outlined
    :style="{
      cursor: task?.sbscode && viewerStyle === '3d' ? 'pointer' : '',
    }"
    :ripple="false"
  >
    <div class="px-3 py-2">
      <div class="d-flex align-center">
        <v-icon
          class="mr-2"
          :color="
            task.status === 'closed'
              ? 'success'
              : task.status === 'canceled'
                ? 'warning'
                : ''
          "
        >
          {{
            task.status === 'closed'
              ? 'mdi-check-circle'
              : task.status === 'canceled'
                ? 'mdi-cancel'
                : task?.sbscode
                  ? 'mdi-cube-outline'
                  : task.task_type.type === 'dhme-quality-assurance'
                    ? 'mdi-form-select'
                    : 'mdi-wrench'
          }}
        </v-icon>
        <span
          :style="{
            'text-decoration':
              task.status === 'canceled' ? 'line-through' : 'none',
          }"
          >{{ taskTitle }}</span
        >
        <v-spacer />
        <span
          v-if="
            task.task_type.custom_4 &&
            task.task_type.type === 'dhme-module-assembly-station'
          "
          class="mx-2"
          >{{ task.task_type.custom_4 }}</span
        >
        <v-btn
          v-if="task.status !== 'open'"
          small
          color="primary"
          @click="reopenTask"
          >reopen</v-btn
        >
      </div>
    </div>
    <v-expansion-panels flat class="ant-border-top">
      <v-expansion-panel v-if="task.appendixes.length > 0">
        <v-expansion-panel-header class="px-4 py-1" style="height: 40px">
          <template #default="{ open }">
            <v-fade-transition leave-absolute>
              <div v-if="open">
                <v-icon class="flex-grow-0 mr-2">mdi-file-image</v-icon>
                {{ text('captures') }}
              </div>
              <v-list-item v-else two-line class="pa-0">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ task.appendixes.length }}
                    {{ text('captures') }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-fade-transition>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              v-for="(attachment, index) in task.appendixes"
              :key="attachment.id"
              cols="12"
              md="4"
            >
              <v-card
                outlined
                elevation="0"
                style="height: 60px; width: 80px"
                class="task-appendix ant-glass-background mx-1 d-flex align-center justify-center pa-2"
                @click="$emit('previewImage', attachment)"
              >
                <div class="d-flex flex-column full-width">
                  <v-icon small>mdi-file</v-icon>
                  <div
                    class="text-no-wrap overflow-hidden"
                    style="text-overflow: ellipsis"
                  >
                    {{ attachment.name }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="task.activities.length > 0">
        <v-expansion-panel-header class="px-4 py-1" style="height: 40px">
          <template #default="{ open }">
            <v-fade-transition leave-absolute>
              <div v-if="open">
                <v-icon class="flex-grow-0 mr-2">mdi-message-badge</v-icon>
                {{ text('issues') }}
              </div>
              <v-list-item v-else two-line class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{
                    task.activities[0].value
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="font-italic fs-10"
                      >{{ formatDate(task.activities[0].created_at) }} -
                      {{ formatTime(task.activities[0].created_at) }}</span
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-fade-transition>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item v-for="activity in task.activities" :key="activity.id">
              <v-list-item-icon class="mr-2"
                ><v-icon small>mdi-message-reply-text</v-icon></v-list-item-icon
              >
              {{ activity.value }}
              <v-spacer />
              <div class="d-flex flex-column font-italic fs-10">
                <span>{{ formatDate(activity.created_at) }}</span>
                <span>{{ formatTime(activity.created_at) }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<style scoped lang="scss">
.sub-task-card-container {
  min-width: 400px;
}
</style>
