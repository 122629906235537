<script>
import { defineComponent } from 'vue';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import {
  DHME_M_ASSEMBLY_HALL_DASHBOARD,
  DHME_M_STATION_DASHBOARD,
} from '@/modules/modules';
import AntLoading from '@/components/AntLoading.vue';
import CancelModuleDialog from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardCancelModuleDialog.vue';
import LinkTactDialog from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardLinkTactDialog.vue';
import DhmeStationDashboardAssemblyTaskCard from '@/modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboardAssemblyTaskCard.vue';
import { LaravelEchoClient } from '@/services/laravel-echo';
import {
  downloadAppendixV2,
  importTasksV2,
  queryTasksV2,
} from '@/services/api/v2/tasks.v2.api';
import ColorHelper, {
  getBlackOrWhiteBasedOnBackground,
} from '@/services/color-helper';
import DhmeHallDashboardAssemblySubTaskCard from '@/modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeHallDashboardAssemblySubTaskCard.vue';
import FileHandlerService from '@/services/file-handler';

export default defineComponent({
  name: 'DhmeAssemblyHallDashboardPlanning',
  components: {
    DhmeHallDashboardAssemblySubTaskCard,
    DhmeStationDashboardAssemblyTaskCard,
    LinkTactDialog,
    CancelModuleDialog,
    AntLoading,
  },
  data: () => {
    return {
      assemblyTasks: [],
      focusedTask: null,
      isLoading: false,
      cancelDialog: false,
      linkTactDialog: false,
      assemblySubTasks: [],
      assemblyLocations: [],
      editModeActive: false,
      filterModulesTab: 'todo',
      taskToMove: null,
      statusProject: null,
      projectConfigurationId: null,
      projectStatus: null,
      projectStatusLoading: false,
      filteredLane: [],
      stepTab: 0,
      searchModule: '',
      previewDialog: false,
      imagePreview: null,
    };
  },
  computed: {
    ColorHelper() {
      return ColorHelper;
    },
    ...mapGetters({
      project: 'project',
      focusedHall: 'dhmeAssemblyHallDashboardStore/focusedHall',
      activeLocations: 'dhmeAssemblyHallDashboardStore/activeHallLocations',
      hallLocations: 'dhmeAssemblyHallDashboardStore/hallLocations',
      projects: 'dhmeAssemblyHallDashboardStore/hallProjects',
      hallLanes: 'dhmeAssemblyHallDashboardStore/hallLanes',
      configurationSteps: 'dhmeAssemblyHallDashboardStore/configurationSteps',
    }),
    locations() {
      let locations = [];
      if (this.statusProject) {
        locations = this.hallLocations.filter(
          (l) => l.hall_configuration === this.projectConfigurationId
        );
      } else {
        locations = this.activeLocations.map((x) => x.location);
      }

      return locations
        .filter((l) =>
          this.filteredLane.length > 0
            ? this.filteredLane.includes(l.assembly_lane)
            : true
        )
        .map((l) => {
          l.lane = this.hallLanes.find((y) => y.id === l.assembly_lane);
          return l;
        })
        .sort((a, b) => a.lane?.order - b.lane?.order || a.order - b.order);
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_ASSEMBLY_HALL_DASHBOARD
      ).id;
    },
    stationDashboardModuleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_M_STATION_DASHBOARD
      ).id;
    },
    activeProject() {
      const task = this.assemblyTasks[0];
      if (task) {
        return this.projects?.find((p) => p.id === task.project);
      }
      return null;
    },
    activeProjects() {
      return [
        ...new Map(
          this.assemblyTasks
            .map((t) => t.task_project)
            .map((item) => [item.id, item])
        ).values(),
      ];
    },
    activeLanes() {
      let locations = [];
      if (this.statusProject) {
        locations = this.hallLocations.filter(
          (l) => l.hall_configuration === this.projectConfigurationId
        );
      } else {
        locations = this.activeLocations.map((x) => x.location);
      }

      return this.hallLanes.filter((l) =>
        locations.some((x) => x.assembly_lane === l.id)
      );
    },
    filteredAssemblyTasks() {
      if (this.searchModule) {
        return this.assemblyTasks.filter((t) => {
          if (
            t.assemblyItem.id.includes(this.searchModule) ||
            t.assemblyItem.code.includes(this.searchModule) ||
            t.assemblyItem.type.includes(this.searchModule)
          ) {
            return true;
          }
        });
      }
      return this.assemblyTasks;
    },
    toDoTasks() {
      return this.filteredAssemblyTasks.filter((t) => {
        if (!['open', 'canceled'].includes(t.status) || t.task_type.custom_9) {
          return false;
        }

        if (t.task_type.custom_11) {
          const sameCustom11Tasks = this.assemblyTasks
            .filter((t) => t.status !== 'closed')
            .filter(
              (task) => task.task_type.custom_11 === t.task_type.custom_11
            );

          const earliestPlannedStart = sameCustom11Tasks.reduce(
            (earliest, currentTask) =>
              moment(currentTask.planned_start).isBefore(
                moment(earliest.planned_start)
              )
                ? currentTask
                : earliest
          ).planned_start;

          if (!moment(t.planned_start).isAfter(moment(earliestPlannedStart))) {
            return false;
          }
        }

        return true;
      });
    },
    outHallTasks() {
      return this.filteredAssemblyTasks
        .filter(
          (t) =>
            (!t.task_type.custom_11 && t.task_type.custom_9) ||
            t.status === 'closed'
        )
        .sort(
          (a, b) =>
            moment(b.planned_start).unix() - moment(a.planned_start).unix()
        );
    },
    tasksOnLocations() {
      return this.filteredAssemblyTasks.filter(
        (t) => ['open', 'canceled'].includes(t.status) && t.task_type.custom_11
      );
    },
    assemblyLocationSteps() {
      let steps = Array.from(
        new Set(this.assemblySubTasks.map((t) => t?.task_type?.custom_6))
      );

      // Check if all elements are UUIDs
      let isUuid = steps.every((step) => {
        const uuidRegex =
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
        return uuidRegex.test(step);
      });

      if (isUuid) {
        return this.configurationSteps
          .filter((s) => steps.includes(s.id))
          .sort((a, b) => parseInt(a.order) - parseInt(b.order));
      } else {
        return steps.sort((a, b) => parseInt(a) - parseInt(b));
      }
    },
    totalProgressAcrossSteps() {
      const closedTasks = this.assemblySubTasks.filter((t) =>
        ['closed', 'canceled'].includes(t.status)
      ).length;

      if (closedTasks > 0) {
        return Math.ceil((closedTasks / this.assemblySubTasks.length) * 100);
      }
      return 0;
    },
  },
  watch: {
    focusedHall(value) {
      if (value) {
        this.fetchModuleAssemblyTasks();
      }
    },
    async focusedTask(value) {
      if (value) {
        this.assemblySubTasks = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchAssemblySubTasks',
          {
            parent: value.id,
          }
        );

        this.assemblyLocations = this.hallLocations
          .filter((l) =>
            this.assemblySubTasks.some((t) => t.task_type.custom_2 === l.id)
          )
          .sort((l1, l2) => l1.assembly_location - l2.assembly_location);
      }
    },
    async statusProject(value) {
      if (value) {
        this.projectStatusLoading = true;
        this.projectStatus = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'fetchProjectStatus',
          {
            project: value.id,
          }
        );
        this.projectStatusLoading = false;

        let taskResponse = await queryTasksV2(value.license, [
          {
            column: 'project',
            operator: '=',
            values: [value.id],
          },
          {
            column: 'type',
            operator: '=',
            values: ['dhme-assembly-phase'],
          },
        ]);
        if (taskResponse.tasks.length === 1) {
          this.projectConfigurationId =
            taskResponse.tasks[0].task_type.custom_4;
        } else {
          this.$store.commit('showNotification', {
            content: 'Assembly task not found for current project',
            color: 'error',
          });
        }
      } else {
        this.projectStatus = null;
        await this.$store.dispatch('selectAssemblyHall', this.focusedHall);
      }

      await this.fetchModuleAssemblyTasks();
    },
  },
  mounted() {
    this.fetchModuleAssemblyTasks();
    this.$store.dispatch('dhmeAssemblyHallDashboardStore/fetchHallTacts');
  },
  methods: {
    async previewImage(attachment) {
      let file = await downloadAppendixV2(attachment.task, attachment.id);

      let blob = await FileHandlerService.convertBase64ToBlob(
        file.file,
        file.mimetype
      );

      attachment.url = URL.createObjectURL(blob);

      this.imagePreview = attachment;
      this.previewDialog = true;
    },
    getBlackOrWhiteBasedOnBackground,
    async reinstateOfflineAGV() {
      await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'reinstateOfflineAgv',
        {
          task: this.focusedTask.id,
          hall: this.focusedHall.id,
          stationDashboardModule: this.stationDashboardModuleId,
        }
      );

      await this.fetchModuleAssemblyTasks();
    },
    fetchModuleAssemblyTasks: async function () {
      this.isLoading = true;
      this.focusedTask = null;
      let data = {
        hall: this.focusedHall.id,
      };
      if (this.statusProject) {
        data.project = this.statusProject?.id;
      }
      this.assemblyTasks = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'fetchHallAssemblyTasks',
        data
      );
      this.isLoading = false;
    },
    getTasksByLocation(locationId) {
      return this.assemblySubTasks
        .filter(
          (t) =>
            t.task_type.custom_2 === locationId &&
            (this.assemblyLocationSteps[this.stepTab]?.id ??
              this.assemblyLocationSteps[this.stepTab]) ===
              t.task_type?.custom_6?.toString()
        )
        .sort(
          (a, b) =>
            parseFloat(a.task_type.custom_5) - parseFloat(b.task_type.custom_5)
        );
    },
    async exportCurrentProject() {
      const response = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'createClosedTaskExport',
        { hall: this.selectedAssemblyHall.id, project: this.activeProject.id }
      );
    },
    forceReloadLocation(locationId) {
      LaravelEchoClient.private(
        `App.Project.${this.project.id}.Module.${this.stationDashboardModuleId}.Location.${locationId}`
      ).whisper('reloadData', true);
      this.$store.commit('showNotification', {
        content: 'Pushed force reload to location',
        color: 'info',
      });
    },
    forceReloadLocations() {
      this.assemblyLocations.forEach((l) => {
        LaravelEchoClient.private(
          `App.Project.${this.project.id}.Module.${this.stationDashboardModuleId}.Location.${l.id}`
        ).whisper('reloadData', true);
      });
      this.$store.commit('showNotification', {
        content: 'Pushed data reload to all locations',
        color: 'info',
      });
    },
    hardReloadLocations() {
      this.assemblyLocations.forEach((l) => {
        LaravelEchoClient.private(
          `App.Project.${this.project.id}.Module.${this.stationDashboardModuleId}.Location.${l.id}`
        ).whisper('hardReload', true);
      });
      this.$store.commit('showNotification', {
        content: 'Pushed hard reload to all locations',
        color: 'info',
      });
    },
    async toggleSubTask(task) {
      let status = task.status === 'closed' ? 'open' : 'closed';
      await importTasksV2([
        {
          id: task.id,
          status: status,
        },
      ]);
      task.status = status;
      this.focusedTask.status = this.assemblySubTasks.some(
        (t) => t.status === 'open'
      )
        ? 'open'
        : 'closed';
      this.$store.commit('showNotification', {
        content: `Task status updated to ${status}`,
        color: 'info',
      });
    },
    async addSpaceAfterThisTask() {
      const task = {
        title: 'Space',
        parent: this.focusedTask.parent,
        project: this.focusedTask.task_project.id,
        planned_start: moment(this.focusedTask.planned_start)
          .add(10, 'second')
          .format('YYYY-MM-DD HH:mm:ss'),
        planned_end: moment(this.focusedTask.planned_end)
          .add(10, 'second')
          .format('YYYY-MM-DD HH:mm:ss'),
        options: {
          type: 'dhme-assembly-space',
          custom_2: this.focusedTask.task_type.custom_2,
          custom_3: this.focusedTask.task_type.custom_3,
        },
      };

      const tasks = await importTasksV2([task]);

      this.assemblyTasks = [...this.assemblyTasks, ...tasks].sort(
        (a, b) =>
          moment(a.planned_start).unix() - moment(b.planned_start).unix()
      );
    },
    reopenTask(task) {
      importTasksV2([
        {
          id: task.id,
          status: 'open',
        },
      ]);
      task.status = 'open';
    },
    cancelSubtask(task) {
      importTasksV2([
        {
          id: task.id,
          status: 'canceled',
        },
      ]);
      task.status = 'canceled';
    },
    setupTaskToMove(task) {
      this.taskToMove = task;
    },
    moveTask(newLocation) {
      importTasksV2([
        {
          id: this.taskToMove.id,
          options: {
            custom_2: newLocation.id,
          },
        },
      ]);
      this.taskToMove.task_type.custom_2 = newLocation.id;
      this.taskToMove = null;
    },
    setTaskOnLocation(locationId) {
      let data = {
        id: this.focusedTask.id,
        options: {
          custom_11: locationId,
        },
      };

      if (!this.focusedHall.automated) {
        data.options.custom_3 = locationId;
      }
      importTasksV2([data]);

      if (!this.focusedHall.automated) {
        this.focusedTask.task_type.custom_3 = locationId;
      }
      this.focusedTask.task_type.custom_11 = locationId;
    },
    unlinkTact() {
      let data = {
        id: this.focusedTask.id,
        options: {
          custom_9: null,
        },
      };

      importTasksV2([data]);

      this.focusedTask.task_type.custom_9 = null;
    },
    unlinkLocation() {
      let data = {
        id: this.focusedTask.id,
        options: {
          custom_11: null,
        },
      };

      if (!this.focusedHall.automated) {
        data.options.custom_3 = null;
      }
      importTasksV2([data]);

      if (!this.focusedHall.automated) {
        this.focusedTask.task_type.custom_3 = null;
      }
      this.focusedTask.task_type.custom_11 = null;
    },
    async markAsComplete() {
      try {
        await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'completeAssembly',
          {
            task: this.focusedTask.id,
          }
        );
        this.focusedTask.status = 'closed';
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      }
    },

    sendManuallyLocationOk(locationId) {
      executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'manuallySendLocationOk',
        {
          location: locationId,
        }
      );
    },
    focusOnAssemblyTask(task) {
      if (task === this.focusedTask) {
        this.focusedTask = null;
      } else {
        this.focusedTask = task;
      }
    },
    async requestAssemblyReport() {
      const response = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'exportProjectAssemblyReport',
        {
          project: this.statusProject.id,
        }
      );
      this.$store.commit('showNotification', {
        content: response.message,
        color: 'info',
      });
    },
  },
});
</script>

<template>
  <div class="d-flex flex-column" style="width: 0">
    <v-toolbar dense flat height="50" style="max-height: 50px">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" class="mr-2" v-on="on">
            mdi-information-box-outline
          </v-icon>
        </template>
        <span>In this toolbar you can see the project current status </span>
      </v-tooltip>
      <v-combobox
        v-model="statusProject"
        :items="activeProjects"
        hide-details
        :loading="projectStatusLoading"
        single-line
        dense
        filled
        style="max-width: 300px"
        clearable
        placeholder="Project"
        item-text="name"
        item-value="id"
        class="mr-5"
      >
        <template #item="{ item }">
          {{ item.name }}
          <v-spacer />
          <v-chip small>{{ item.number }}</v-chip>
        </template>
      </v-combobox>
      <v-text-field
        v-model="searchModule"
        hide-details
        single-line
        dense
        filled
        style="max-width: 200px"
        clearable
        placeholder="Search module"
        item-text="name"
        item-value="id"
        prepend-inner-icon="mdi-magnify"
      />
      <v-spacer />
      <div v-if="projectStatus">
        <span v-if="focusedHall.automated">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" class="mx-2" v-on="on"> mdi-cube</v-icon>
            </template>
            <span>Modules through moved through hall</span>
          </v-tooltip>
          {{ projectStatus.modules_through_hall }}/{{
            projectStatus.modules_total
          }}
        </span>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" class="mx-2" v-on="on">
              mdi-checkbox-outline
            </v-icon>
          </template>
          <span>Modules fully completed</span>
        </v-tooltip>
        {{ projectStatus.modules_done }}/{{ projectStatus.modules_total }}

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" class="mx-2" v-on="on">
              mdi-keyboard-space
            </v-icon>
          </template>
          <span>Spaces created in project </span>
        </v-tooltip>
        {{ projectStatus.spaces_created }}
      </div>
      <v-tooltip v-if="statusProject" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="primary"
            class="ml-4"
            small
            elevation="0"
            v-on="on"
            @click="requestAssemblyReport"
            ><v-icon class="mr-2">mdi-export</v-icon>export</v-btn
          >
        </template>
        <span>Export assembly report </span>
      </v-tooltip>
    </v-toolbar>
    <div class="d-flex flex-1 overflow-y-auto">
      <div
        class="d-flex flex-column overflow-y-auto full-height"
        style="width: 20%"
      >
        <v-toolbar dense flat height="50" style="max-height: 50px">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" class="mr-2" v-on="on">
                mdi-information-box-outline
              </v-icon>
            </template>
            <span>Below you will the tasks which are planned for today </span>
          </v-tooltip>
          <span> TO DO ({{ toDoTasks?.length ?? '-' }})</span>
        </v-toolbar>
        <div v-if="!isLoading" class="overflow-y-auto flex-1 my-2 px-2">
          <dhme-station-dashboard-assembly-task-card
            v-for="task in toDoTasks"
            :key="task.id"
            :task="task"
            :focused-task="focusedTask"
            :details="true"
            @click="focusOnAssemblyTask(task)"
          />
        </div>
        <div v-else class="flex-1 d-flex align-center justify-center">
          <ant-loading />
        </div>
      </div>
      <div class="d-flex flex-column flex-1" style="width: 40%">
        <v-toolbar dense flat height="50" style="max-height: 50px">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" class="mr-2" v-on="on">
                mdi-information-box-outline
              </v-icon>
            </template>
            <span
              >Below you will the current situation of the selected hall
            </span>
          </v-tooltip>
          <v-chip class="mr-2" small>{{ focusedHall.title }}</v-chip>
          IN HALL ({{ tasksOnLocations?.length ?? '-' }})
          <v-spacer />
          <v-select
            v-if="activeLanes.length > 1"
            v-model="filteredLane"
            filled
            :items="activeLanes"
            hide-details
            dense
            single-line
            multiple
            clearable
            item-text="title"
            item-value="id"
            placeholder="Lane"
            class="flex-grow-0"
            style="width: 250px"
            prepend-icon="mdi-filter"
          >
            <template #item="{ item }">
              <v-checkbox
                class="ma-0"
                readonly
                hide-details
                :ripple="false"
                :value="filteredLane.includes(item.id)"
              />
              <v-chip
                class="mr-5"
                small
                :color="item.color"
                :text-color="getBlackOrWhiteBasedOnBackground(item.color)"
                >{{ item.title }}
              </v-chip>
            </template>
            <template #selection="{ item, index }">
              <v-chip
                v-if="index === 0"
                :color="item.color"
                small
                :text-color="getBlackOrWhiteBasedOnBackground(item.color)"
              >
                <span>{{ item.title }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ filteredLane.length - 1 }}
                {{ filteredLane.length - 1 > 1 ? 'others' : 'other' }})
              </span>
            </template>
          </v-select>

          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-icon class="ant-icon ml-2" dense v-bind="attrs" v-on="on"
                >mdi-dots-vertical
              </v-icon>
            </template>
            <v-list dense>
              <v-list-item
                v-if="focusedTask"
                :disabled="
                  focusedTask.status !== 'open' ||
                  focusedTask.task_type.type !== 'dhme-module-assembly'
                "
                @click="linkTactDialog = true"
              >
                <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
                <v-list-item-title>Link tact</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask?.task_type.custom_9"
                @click="unlinkTact"
              >
                <v-list-item-icon
                  ><v-icon>mdi-link-off</v-icon></v-list-item-icon
                >
                <v-list-item-title>Unlink task tact</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask?.task_type.custom_11"
                @click="unlinkLocation"
              >
                <v-list-item-icon
                  ><v-icon>mdi-link-variant-off</v-icon></v-list-item-icon
                >
                <v-list-item-title>Remove from location</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask?.status === 'closed'"
                dense
                @click="reopenTask"
              >
                <v-list-item-icon>
                  <v-icon dense> mdi-clipboard-arrow-left</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Reopen Task</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask?.status === 'open'"
                @click="markAsComplete"
              >
                <v-list-item-icon
                  ><v-icon
                    >mdi-checkbox-marked-outline</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-title>Mark as complete</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask"
                :disabled="
                  focusedTask.status !== 'open' ||
                  focusedTask.task_type.type !== 'dhme-module-assembly'
                "
                @click="cancelDialog = true"
              >
                <v-list-item-icon><v-icon>mdi-cancel</v-icon></v-list-item-icon>
                <v-list-item-title>Cancel module</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  focusedTask && focusedTask.task_type.custom_7 === 'inactive'
                "
                @click="reinstateOfflineAGV"
              >
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title>Reinstate offline AGV</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask"
                dense
                @click="forceReloadLocations"
              >
                <v-list-item-icon>
                  <v-icon dense> mdi-reload</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Reload data on all locations
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="exportCurrentProject">
                <v-list-item-icon>
                  <v-icon dense> mdi-file-download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Export {{ activeProject?.name }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask"
                dense
                @click="hardReloadLocations"
              >
                <v-list-item-icon>
                  <v-icon dense> mdi-reload-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  >Hard reload all locations
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="focusedTask"
                dense
                @click="addSpaceAfterThisTask"
              >
                <v-list-item-icon>
                  <v-icon dense> mdi-keyboard-space</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  >Add space after this task
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <cancel-module-dialog
            v-if="focusedTask"
            :displayed="cancelDialog"
            :module-task="focusedTask"
            @closeDialog="cancelDialog = false"
            @canceledModule="fetchModuleAssemblyTasks"
          />
          <link-tact-dialog
            v-if="focusedTask"
            :displayed="linkTactDialog"
            :task="focusedTask"
            @closeDialog="linkTactDialog = false"
          />
        </v-toolbar>

        <div class="flex-1 overflow-y-auto pa-2">
          <div
            v-if="locations.length === 0 && !statusProject"
            class="font-italic"
          >
            There are no active locations
          </div>
          <div
            v-for="lane in activeLanes"
            :key="lane.id"
            class="d-flex flex-column mb-2 mt-5"
          >
            <div class="d-flex align-center">
              <v-chip
                v-if="lane"
                class="mr-5"
                :color="lane.color"
                :text-color="getBlackOrWhiteBasedOnBackground(lane.color)"
                >{{ lane.title }}
              </v-chip>
              <v-chip outlined small>
                has {{ locations.filter((l) => l.lane.id === lane.id).length }}
                Locations
              </v-chip>
            </div>

            <div
              v-for="location in locations.filter((l) => l.lane.id === lane.id)"
              :key="location.id"
              class="d-flex flex-column px-4"
            >
              <div class="d-flex align-center py-2">
                <span class="fs-12 font-italic mx-2">{{ location.order }}</span>
                <span class="mr-5">{{ location.assembly_location }}</span>
                <span class="font-italic">{{ location.description }}</span>
                <v-spacer />
                <v-chip
                  v-if="
                    tasksOnLocations.filter(
                      (t) => t.task_type.custom_11 === location.id
                    ).length > 0
                  "
                  small
                  label
                  outlined
                  >{{
                    tasksOnLocations.find(
                      (t) => t.task_type.custom_11 === location.id
                    )?.task_project?.name
                  }}
                </v-chip>
                <v-menu v-if="focusedHall.automated" bottom left>
                  <template #activator="{ on, attrs }">
                    <v-icon class="ant-icon ml-2" dense v-bind="attrs" v-on="on"
                      >mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item @click="sendManuallyLocationOk(location.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon dense> mdi-check-network</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Send location OK to mes
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div
                v-if="
                  focusedTask &&
                  !focusedTask?.task_type.custom_11 &&
                  statusProject
                "
                class="place-here-container d-flex align-center justify-center full-height rounded overflow-hidden cursor-pointer mb-2"
                @click="setTaskOnLocation(location.id)"
              >
                Place here
              </div>
              <dhme-station-dashboard-assembly-task-card
                v-for="task in tasksOnLocations.filter(
                  (t) =>
                    t.task_type.custom_11 === location.id &&
                    !toDoTasks.some((x) => x.id === t.id)
                )"
                :key="task.id"
                :task="task"
                :details="true"
                :focused-task="focusedTask"
                @click="focusOnAssemblyTask(task)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column overflow-y-auto full-height"
        style="width: 20%"
      >
        <v-toolbar dense flat height="50" style="max-height: 50px">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" class="mr-2" v-on="on">
                mdi-information-box-outline
              </v-icon>
            </template>
            <span
              >Below you will the tasks which are moved out of the hall
            </span>
          </v-tooltip>
          <span> OUT HALL ({{ outHallTasks?.length ?? '-' }})</span>
        </v-toolbar>
        <div v-if="!isLoading" class="overflow-y-auto flex-1 my-2 px-2">
          <dhme-station-dashboard-assembly-task-card
            v-for="task in outHallTasks"
            :key="task.id"
            :task="task"
            :focused-task="focusedTask"
            :details="true"
            @click="focusOnAssemblyTask(task)"
          />
        </div>
        <div v-else class="flex-1 d-flex align-center justify-center">
          <ant-loading />
        </div>
      </div>
      <div class="d-flex flex-column" style="width: 30%">
        <v-toolbar dense flat height="50" style="max-height: 50px">
          <span> Tasks ({{ assemblySubTasks?.length ?? '-' }})</span>
        </v-toolbar>
        <div
          v-if="focusedTask"
          class="d-flex ant-border-left-darker full-width overflow-y-auto flex-1"
        >
          <v-dialog key="table-preview" v-model="previewDialog">
            <div style="height: 80vh" class="iframe-container">
              <v-img
                contain
                :src="imagePreview?.url"
                height="100%"
                width="100%"
              />
              <div class="iframe-actions-container">
                <v-btn color="error" small plain @click="previewDialog = false">
                  <v-icon> mdi-close </v-icon>Close
                </v-btn>
              </div>
            </div>
          </v-dialog>
          <div class="d-flex flex-column flex-1 overflow-y-auto">
            <div
              v-if="assemblyLocations.length > 0"
              class="overflow-y-auto flex-1"
            >
              <div
                v-for="location in assemblyLocations"
                :key="location.id"
                class="px-2 py-4 ant-border-bottom"
              >
                <v-subheader>
                  Station {{ location.assembly_location }} -
                  {{ location.description }}
                  <v-btn
                    v-if="
                      taskToMove &&
                      taskToMove?.task_type?.custom_2 !== location.id
                    "
                    class="ml-5"
                    small
                    elevation="0"
                    color="primary"
                    @click="moveTask(location)"
                    >move here
                  </v-btn>
                  <v-spacer />
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="forceReloadLocation(location.id)"
                      >
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>
                    </template>
                    <span>Reload location</span>
                  </v-tooltip>
                </v-subheader>
                <v-progress-linear
                  height="15"
                  :value="totalProgressAcrossSteps"
                  color="primary"
                  class="mb-2"
                  rounded
                >
                  <strong
                    class="fs-10"
                    :style="{
                      color: getBlackOrWhiteBasedOnBackground(
                        $vuetify.theme.themes.light.primary
                      ),
                    }"
                    >{{ Math.ceil(totalProgressAcrossSteps) }}%</strong
                  >
                </v-progress-linear>
                <v-card class="mb-2 overflow-hidden" outlined>
                  <v-tabs
                    v-model="stepTab"
                    center-active
                    show-arrows
                    color="primary"
                    grow
                  >
                    <v-tab
                      v-for="step in assemblyLocationSteps"
                      :key="`assembly-location-step-${step?.id ? step.id : step}`"
                      >{{ step?.id ? `${step?.order} - ${step?.title}` : step }}
                    </v-tab>
                  </v-tabs>
                </v-card>
                <div class="d-flex">
                  <div class="flex-1">
                    <dhme-hall-dashboard-assembly-sub-task-card
                      v-for="task in getTasksByLocation(location.id)"
                      :key="`${task.id}-${task.status}`"
                      :task="task"
                      @previewImage="previewImage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="mt-5 mx-5">
              Tasks will be automatically generated within the
              <span class="font-weight-bold">Station Dashboard App</span> or
              manually created using the
              <span class="font-weight-bold">Assembly Preparations App </span>
              within the project.
            </div>
          </div>
        </div>
        <div v-else class="mt-5 mx-5 font-italic">
          Please select a task first
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.place-here-container {
  width: 100%;
  height: 70px;
  border: dashed 2px var(--v-primary-base);
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.iframe-container {
  position: relative;

  .iframe-actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(8px);
    border-radius: 5px;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 100px);
    z-index: 1;
    padding: 12px 16px;
  }
}
</style>
